// React
import { createContext, useEffect, useState } from 'react'

// Routing
import { Route, Routes, useLocation } from 'react-router-dom'

// Analytics
import { useMatomo } from '@datapunt/matomo-tracker-react'

// Localization
import { IntlProvider } from 'react-intl'
import KuvapankkiLang from './utils/kuvapankki-lang.json'

// Bootstrap
import Container from 'react-bootstrap/Container'

// Components
import Ylapalkki from './components/Ylapalkki'
// import KyselyNotification from './components/KyselyNotification'

// Pages
// import Tietoa from './pages/Tietoa'
import HaeKuvia from './pages/HaeKuvia'
import SelaaKuvia from './pages/SelaaKuvia'
import NotFound from './pages/NotFound'

// Styling
import './styles/App.scss'

// React Context, sijoitettava kaikkien importtien jälkeen
const StateContext = createContext('')

const App = () => {
  const [state, setState] = useState({
    type: [
      'ARASAAC',
      'KUVAKO',
      'Mulberry',
      'Piirroskuva',
      'Sclera',
      'Toisto',
      'Valokuva',
      'Viittomakuva',
      'Viittomavideo',
    ],
    arasaac: true,
    kuvako: true,
    mulberry: true,
    drawing: true,
    sclera: true,
    toisto: true,
    photo: true,
    sign: true,
    viittomavideo: window.location.hostname === 'bildbank.papunet.net' || window.location.hostname === 'testi.papunet.net' ? false : true,
    haeURL: '/',
    selaaURL: '/selaa',
    lang: window.location.hostname === 'bildbank.papunet.net' || window.location.hostname === 'testi.papunet.net' ? 'se' : 'fi',
  })

  const { arasaac, kuvako, mulberry, drawing, sclera, toisto, photo, sign, viittomavideo, lang } =
    state

  const messages = KuvapankkiLang
  const location = useLocation()
  const { trackPageView } = useMatomo()

  const updateType = () => {
    let checkList = []

    if (arasaac) checkList.push('ARASAAC')
    if (kuvako) checkList.push('KUVAKO')
    if (mulberry) checkList.push('Mulberry')
    if (drawing) checkList.push('Piirroskuva')
    if (sclera) checkList.push('Sclera')
    if (toisto) checkList.push('Toisto')
    if (photo) checkList.push('Valokuva')
    if (sign) checkList.push('Viittomakuva')
    if (viittomavideo && lang === 'fi') checkList.push('Viittomavideo')
    if (lang === 'se') checkList = checkList.filter(item => item !== 'Viittomavideo')

    setState({ ...state, type: checkList })
  }

  const updateHaeURL = (url) => {
    setState({ ...state, haeURL: url })
  }

  const updateSelaaURL = (url) => {
    setState({ ...state, selaaURL: url })
  }

  const setLang = () => {
    document.documentElement.setAttribute('lang', lang)
    document.title = messages[lang].Title
  }

  // const changeLang = (lang) => {
  //   setState({ ...state, lang: lang }, setLang())
  // }

  useEffect(() => {
    trackPageView()
    //eslint-disable-next-line
  }, [location.pathname])

  useEffect(() => {
    setLang()
    updateType()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    updateType()
    //eslint-disable-next-line
  }, [arasaac, kuvako, mulberry, drawing, sclera, toisto, photo, sign, viittomavideo])

  return (
    <StateContext.Provider value={[state, setState]}>
      <IntlProvider locale={lang} messages={messages[lang]}>
        <div className='App'>
          <Ylapalkki lang={lang} />
          <Container fluid className='AppContent'>
            <Routes>
              <Route index element={<HaeKuvia updateHaeURL={updateHaeURL} lang={lang} />} />
              <Route path='haku' element={<HaeKuvia updateHaeURL={updateHaeURL} lang={lang} />} />
              <Route path='/haku/:input' element={<HaeKuvia updateHaeURL={updateHaeURL} lang={lang} />} />
              <Route path='selaa' element={<SelaaKuvia updateSelaaURL={updateSelaaURL} lang={lang} />} />
              <Route path='/selaa/:cat1' element={<SelaaKuvia updateSelaaURL={updateSelaaURL} lang={lang} />} />
              <Route
                path='/selaa/:cat1/:cat2'
                element={<SelaaKuvia updateSelaaURL={updateSelaaURL} lang={lang} />}
              />
              {/* <Route path='tietoa' element={<Tietoa lang={lang} />} /> */}
              <Route path='*' element={<NotFound />} />
            </Routes>
          </Container>
          {/* <KyselyNotification /> */}
        </div>
      </IntlProvider>
    </StateContext.Provider>
  )
}

export { App, StateContext }
